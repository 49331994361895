import { aes, md5 } from '@/functions/encryption'

import moment from 'moment'

import { createEventFromCode } from '@/functions/events'

import { $classes } from '@/main'

import { isConnectionReady } from '@/functions/network'
import { getCircularReplacer } from '@/functions/json'
import { isQuotaExceededError, quotaExceededErrorResolution } from '@/functions/error'
import { keys } from '@/functions/localStorage'
import { getAsBool } from '@/functions/env'

let _getTokenPromise = null,
	_renewTokenPromise = null
	
const VUE_APP_MODULE_SHARE_DB_ENABLED = getAsBool('VUE_APP_MODULE_SHARE_DB_ENABLED')
const { user, userPassHashKey, userPassHashFromHashKey, passwordEncryptedKey, authEncrypted} = keys

// auth online per user
export const key = user,
	_getToken = async function () {
		const auth = getAuth()

		if (!auth) return

		let { access_token_expire } = auth

		if (Date.now() < access_token_expire) {
			const m = moment(access_token_expire).format('YYYY-MM-DD HH:mm')
			console.debug(`[getToken] access_token wygasa: ${m}`)
			
			return auth?.access_token
		} // else await createEventFromCode('auth-access-token-expired')

		const refresh_token = await getRefreshToken()

		if (refresh_token) {
			const access_token = renewToken(refresh_token)

			return access_token
		}
	},
	getToken = async function () {
		if (_getTokenPromise) return await _getTokenPromise
		else {
			_getTokenPromise = _getToken()
			const access_token = await _getTokenPromise
			_getTokenPromise = null
			return access_token
		}
	},
	isClassesReady = async function () {
		if (!$classes?.AuthService) {
			console.debug(`[isClassesReady] Getting public classes...`)

			try {
				await $classes.loadPublic()
			} catch (e) {
				await createEventFromCode('fetch-classes-public-fail')
				
				throw e
			}
		}
	},
	getTokenBy = async function (data, grant_type = 'password') {
		console.debug(`[getTokenBy] starting...`)
		
		if (grant_type === 'password') {
			const { login, pass } = data
			
			await isConnectionReady()
			await isClassesReady()

			return await $classes.AuthService.token({
				grant_type: 'password',
				username: login,
				password: pass,
				scope: 'provider:Person',
			})
		}

		return null
	},
	getRefreshToken = async function () {
		const auth = getAuth()

		if (!auth) return

		let { refresh_token_expire } = auth

		if (Date.now() < refresh_token_expire) {
			const m = moment(refresh_token_expire).format('YYYY-MM-DD HH:mm')
			console.debug(`[getToken] refresh_token wygasa: ${m}`)
			
			return auth?.refresh_token
		}
		else await createEventFromCode('auth-refresh-token-expired')
	},
	_renewToken = async function (refresh_token_old) {
		console.debug(`[renewToken] starting...`)

		await isConnectionReady()
		await isClassesReady()
		
		try {
			const { access_token, refresh_token, expires_in, refresh_expires_in } =
				await $classes.AuthService.token({
					grant_type: 'refresh_token',
					refresh_token: refresh_token_old,
					scope: 'provider:Person',
				})
	
			setAuth({ access_token, refresh_token, expires_in, refresh_expires_in })
			
			await createEventFromCode('auth-refresh-token-renew-success')
			
			return access_token
		} catch (e) {
			//TODO
			// if(e.message === 'Request failed with status code 401'){
			
			// }
		
			// console.error({ ...e })
			
			await createEventFromCode('auth-refresh-token-renew-fail')
		}

	},
	renewToken = async function (refresh_token_old) {
		if (_renewTokenPromise) return await _renewTokenPromise
		else {
			_renewTokenPromise = _renewToken(refresh_token_old)
			const access_token = await _renewTokenPromise
			_renewTokenPromise = null
			return access_token
		}
	},
	getAuth = function () {
		const info = getUser()

		return info?.auth
	},
	getUser = function () {
		let ret = null

		if (localStorage.getItem(key))
			ret = JSON.parse(localStorage.getItem(key))

		return ret
	},
	getUserLogin = function () {
		const user = getUser()
		
		return user?.instance?.login || '-'
	},
	setAuth = function (auth) {
		console.debug(`[setAuth] saving...`)

		let dataStringified = createAuthObject(auth)

		try {
			localStorage.setItem(key, dataStringified)
		} catch (e) {
			if (isQuotaExceededError(e)) quotaExceededErrorResolution(e)
			else throw e
		}
	},
	is_auth_not_expired = function () {
		const auth = getAuth()

		if (auth?.refresh_token_expire >= Date.now()) return true

		return false
	}
	
// auth offline per user
export const encryptData = function (data, baseForHash) {
		console.debug(`[encryptData]`)
		let hash = md5.encrypt(baseForHash),
			data2 = aes.encrypt(
				data,
				hash
			)
		
		return data2
	},
	decryptData = function (data, baseForHash) {
		console.debug(`[decryptData]`)
		let hash = md5.encrypt(baseForHash),
			data2 = aes.decrypt(
				data,
				hash
			)
		
		return data2
	},
	createAuthObject = function (auth) {
		const now = Date.now(),
			access_token_expire = now + auth.expires_in * 1000,
			refresh_token_expire = now + auth.refresh_expires_in * 1000

		const info = getUser(),
			infoNew = {
				...info,
				auth: {
					...auth,
					access_token_expire: access_token_expire,
					access_token_created: now,
					refresh_token_expire: refresh_token_expire,
					refresh_token_created: now,
				},
			}
		let dataStringified = JSON.stringify(infoNew, getCircularReplacer())
	
		return dataStringified
	},
	setEncryptedAuth = function (auth, userLoginData) {
		console.debug(`[setEncryptedAuth] saving...`)
		
		let dataEncrypted = encryptData(JSON.stringify(auth), userLoginData)
		
		try {
			localStorage.setItem(authEncrypted, dataEncrypted)
		} catch (e) {
			if (isQuotaExceededError(e)) quotaExceededErrorResolution(e)
			else throw e
		}
	},
	getDecryptedAuth = function (userLoginData) {
		let ret = null

		if (localStorage.getItem(authEncrypted))
			ret = JSON.parse(decryptData(localStorage.getItem(authEncrypted), userLoginData))

		return ret
	}
	
// rxdb per user
const { passwordEncryptedKey2, userPassHashKey2 } = keys
export const is_hash_exists = function () {
		let userPassHashDouble = localStorage.getItem(
			userPassHashFromHashKey
		)

		if (userPassHashDouble) return true

		return false
	},
	is_hash_user_pass_hash_exists = function () {
		let userPassHash = sessionStorage.getItem(
			userPassHashKey
		)

		if (userPassHash) return true

		return false
	},
	compare_user_password = function ({ userPassPlain, userEmailPlain }) {
		if (!is_hash_exists()) return null

		const userPassHashDouble = localStorage.getItem(
				userPassHashFromHashKey
			),
			userPassHash = get_user_hash({ userPassPlain, userEmailPlain })

		if (!userPassHash || !userPassHashDouble) {
			console.error(`[compare_user_password] not enough data`)

			return null
		}

		if (md5.encrypt(userPassHash) == userPassHashDouble) return true
		else return false
	},
	set_RxDB_auth_offline = function ({
		userPassPlain,
		userEmailPlain,
	}) {
		if (!userPassPlain || !userEmailPlain) {
			console.debug(`[setRxDbAuthOffline] no password of user or user login`)

			return
		}
		
		const userPassHash = get_user_hash({userPassPlain, userEmailPlain})
		
		if(!userPassHash)
			throw new Error(`[setRxDbAuthOffline] userPassHash is missing`)
		
		console.debug(`[setRxDbAuthOffline] saving rxdb auth config data`)
			
		try {
			sessionStorage.setItem(
				userPassHashKey,
				userPassHash
			)
		} catch (e) {
			if (isQuotaExceededError(e)) quotaExceededErrorResolution(e)
			else throw e
		}
	},
	decrypt_rxdb_password_by_user = function () {
		const rxdbPasswordEncrypted = localStorage.getItem(
				passwordEncryptedKey
			),
			userPassHashDouble = localStorage.getItem(
				userPassHashFromHashKey
			),
			userPassHash = sessionStorage.getItem(
				userPassHashKey
			)
	
		if (
			rxdbPasswordEncrypted &&
			userPassHashDouble &&
			userPassHash &&
			md5.encrypt(userPassHash) == userPassHashDouble
		) {
			const rxdbPasswordPlain = aes.decrypt(
				rxdbPasswordEncrypted,
				userPassHash
			)
	
			return rxdbPasswordPlain
		}
	},
	clear_auth_data = function (){
		sessionStorage.removeItem(userPassHashKey)
	},
	set_RxDB_auth_for_user = function ({
		userPassPlain,
		userEmailPlain,
		rxdbPasswordPlain,
	}) {
		if (!userPassPlain || !rxdbPasswordPlain || !userEmailPlain) {
			console.debug(
				`[setRxDbAuth] no password of user or rxdb or user login`
			)
	
			return
		}
	
		console.debug(`[setRxDbAuth] saving rxdb auth config data`)
	
		try {
			let userPassHash = get_user_hash({ userPassPlain, userEmailPlain }),
				userPassHashDouble = md5.encrypt(userPassHash),
				rxdbPasswordEncrypted = aes.encrypt(
					rxdbPasswordPlain,
					userPassHash
				)
				
			try {
				localStorage.setItem(
					passwordEncryptedKey,
					rxdbPasswordEncrypted
				)
				localStorage.setItem(
					userPassHashFromHashKey,
					userPassHashDouble
				)
				sessionStorage.setItem(
					userPassHashKey,
					userPassHash
				)
			} catch (e) {
				if (isQuotaExceededError(e)) quotaExceededErrorResolution(e)
				else throw e
			}
		} catch (error) {
			console.debug(error)
		}
	}
	
// rxdb
export const is_offline_login_avaliable = is_hash_exists,
	set_RxDB_auth = function ({
		userPassPlain,
		userEmailPlain,
		rxdbPasswordPlain,
		saHash
	}) {
		set_RxDB_auth_for_user({
			userPassPlain,
			userEmailPlain,
			rxdbPasswordPlain,
		})
		sa_set_RxDB_auth({
			rxdbPasswordPlain,
			saHash,
		})
	},
	decrypt_rxdb_password = function () {
		console.debug(`[decrypt_rxdb_password] starting...`)
		const pass1 = decrypt_rxdb_password_by_user(),
			pass2 = decrypt_rxdb_password_by_sa()
		
		if(pass1)
			return pass1
		if(pass2)
			return pass2
		
		return null
	},
	get_user_hash = function ({ userPassPlain, userEmailPlain, saHash }) { 
		if(userPassPlain && userEmailPlain)
			return md5.encrypt(`${userPassPlain}${userEmailPlain}`)
		if(saHash)
			return md5.encrypt(`${saHash}`)
		else console.error(`[get_user_hash] user access data no complete. (userPassPlain: ${userPassPlain ? 'true' : 'false'}, userEmailPlain: ${userEmailPlain ? 'true' : 'false'}, saHash: ${saHash ? 'true' : 'false'})`)
	}

// rxdb shared auth online
const { userPassHashFromHashKey2 } = keys

export const sa_is_hash_exists = function () {
		let userPassHashDouble = localStorage.getItem(
			userPassHashFromHashKey2
		)
	
		if (userPassHashDouble) return true
	
		return false
	},
	decrypt_rxdb_password_by_sa = function () {
		if(!VUE_APP_MODULE_SHARE_DB_ENABLED)
			return null
			
		const rxdbPasswordEncrypted = localStorage.getItem(
				passwordEncryptedKey2
			),
			userPassHashDouble = localStorage.getItem(
				userPassHashFromHashKey2
			),
			userPassHash = sessionStorage.getItem(
				userPassHashKey2
			)
	
		if (
			rxdbPasswordEncrypted &&
			userPassHashDouble &&
			userPassHash &&
			md5.encrypt(userPassHash) == userPassHashDouble
		) {
			const rxdbPasswordPlain = aes.decrypt(
				rxdbPasswordEncrypted,
				userPassHash
			)
	
			return rxdbPasswordPlain
		}
	},
	sa_set_RxDB_auth = function ({
		saHash,
		rxdbPasswordPlain,
	}) {
		if (!VUE_APP_MODULE_SHARE_DB_ENABLED)
			return 
	
		if (!saHash) {
			console.debug(
				`[sa_set_RxDB_auth] no hash`
			)
	
			return
		}
	
		console.debug(`[sa_set_RxDB_auth] saving rxdb auth config data`)
	
		try {
			let userPassHash = get_user_hash({ saHash }),
				userPassHashDouble = md5.encrypt(userPassHash),
				rxdbPasswordEncrypted = aes.encrypt(
					rxdbPasswordPlain,
					userPassHash
				)
				
			try {
				localStorage.setItem(
					passwordEncryptedKey2,
					rxdbPasswordEncrypted
				)
				localStorage.setItem(
					userPassHashFromHashKey2,
					userPassHashDouble
				)
				sessionStorage.setItem(
					userPassHashKey2,
					userPassHash
				)
			} catch (e) {
				if (isQuotaExceededError(e)) quotaExceededErrorResolution(e)
				else throw e
			}
		} catch (error) {
			console.debug(error)
		}
	},
	sa_compare_password = function ({ saHash }) {
		if (!sa_is_hash_exists()) return null

		const userPassHashDouble = localStorage.getItem(
				userPassHashFromHashKey2
			),
			userPassHash = get_user_hash({ saHash })

		if (!userPassHash || !userPassHashDouble) {
			console.error(`[sa_compare_password] not enough data`)

			return null
		}

		if (md5.encrypt(userPassHash) == userPassHashDouble) return true
		else return false
	}
	
// Task: https://etrust.atlassian.net/browse/PT-1098
// Simulate expiring
export const simulate_expire_access_token = function () {
		const auth = getAuth()

		console.debug(`[simulate_expire_access_token] access_token expired`)

		setAuth({ ...auth, expires_in: 0 })
	},
	simulate_expire_refresh_token = function () {
		const auth = getAuth()

		console.debug(`[simulate_expire_refresh_token] refresh_token expired`)

		setAuth({ ...auth, refresh_expires_in: 0 })
	}, 
	simulate_wrong_access_token = function () {
		const auth = getAuth()

		console.debug(`[simulate_wrong_access_token] access_token wrong`)

		setAuth({ ...auth, access_token: 'TEST' })
	},
	simulate_wrong_refresh_token = function () {
		const auth = getAuth()

		console.debug(`[simulate_wrong_refresh_token] refresh_token wrong`)

		setAuth({ ...auth, refresh_token: 'TEST' })
	}

export default getToken